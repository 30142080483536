import type { DetailedMovie, MoviePreview, TrailerMoviePreview } from '~/types/movie'
import type { MfiAPI } from '@hubelia/mfiapi'
import { MoviesIndexes } from '~/consts/meilisearch'
import type { MovieGenre } from '~/types/genre'

export async function fetchMovies(params: {
  locale: string
  sort?: '-cote_added_date'
  recommended?: boolean
  limit?: number
  cote?: number
  additionalFields?: Array<'picture'>
  sdk: MfiAPI
}) {
  const { locale, sort, recommended, limit, additionalFields, sdk, cote } = params

  return await sdk.v1.mfi.movies
    .list({ locale, sort, recommended, limit, additionalFields, cote }, {})
    .then((res) => res.data as Array<MoviePreview>)
}

export async function fetchUpcomingMovies(params: { locale: string; sdk: MfiAPI }) {
  const { locale, sdk } = params

  return await sdk.v1.mfi.movies
    .upcoming({ locale }, {})
    .then((res) => res.data as Array<TrailerMoviePreview>)
}

export async function fetchMovieGenres(params: { locale: string; sdk: MfiAPI }) {
  const { locale, sdk } = params

  return await sdk.v1.mfi.genres.list({ locale }, {}).then((res) => res.data as Array<MovieGenre>)
}

export async function fetchMovie(params: { locale: string; mfiUrl: string; sdk: MfiAPI }) {
  const { locale, mfiUrl, sdk } = params

  return await sdk.v1.mfi.movies.retrieve({ locale, mfiUrl }, {}).then((res) => {
    return res.data as DetailedMovie
  })
}

export async function searchMoviesFacets(params: { locale: string; sdk: MfiAPI }) {
  const { locale, sdk } = params

  const index = MoviesIndexes[locale as 'fr' | 'en']

  if (!index) {
    throw new Error('Unable to get a movies index for the given locale')
  }

  return (await sdk.v1.mfi.search
    .run(index, {
      facets: ['countries', 'year'],
      limit: 0
    })
    .then((res) => {
      return {
        countries: Object.keys(res.data.facetDistribution.countries),
        years: Object.keys(res.data.facetDistribution.year)
      }
    })) as {
    countries: Array<string>
    years: Array<string>
  }
}
